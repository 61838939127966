/* global location history */
import './style'
import './firebase'
import {
  Provider as UnistoreProvider,
  useStoreState,
} from 'hooks/unistore-hooks'
import { useEffect } from 'preact/hooks'
import store from './store'
import Home from 'routes/Home'
import EnterName from 'routes/EnterName'
import JoinGame from 'routes/JoinGame'
import JoiningGame from 'routes/JoiningGame'
import CreatingGame from 'routes/CreatingGame'
import CreatingPracticeGame from 'routes/CreatingPracticeGame'
import Game from 'routes/Game'
import isValidDisplayName from 'utils/isValidDisplayName'
import useAuth, { AuthProvider } from 'hooks/useAuth'

function Router() {
  const { user } = useAuth()
  const { view } = useStoreState('view')

  // Redirect invites
  useEffect(() => {
    if (location?.pathname !== '/') {
      if (location?.pathname.startsWith('/invite/')) {
        const inviteCode = location.pathname.substr('/invite/'.length)
        setTimeout(() => {
          // Same logic as <JoinGame />
          if (!user || !isValidDisplayName(user.displayName)) {
            store.setState({
              view: 'enter-name',
              viewReason:
                'Before joining a game you need to enter a display name',
              stateAfterView: { view: 'joining-game', inviteCode },
            })
          } else {
            store.setState({ view: 'joining-game', inviteCode })
          }
          // Short timeout to let local store load
        }, 100)
      }

      history.replaceState({}, '', '/')
    }
  }, [user?.uid])

  switch (view) {
    case 'enter-name':
      return <EnterName />
    case 'join-game':
      return <JoinGame />
    case 'joining-game':
      return <JoiningGame />
    case 'creating-game':
      return <CreatingGame />
    case 'creating-practice-game':
      return <CreatingPracticeGame />
    case 'game':
      return <Game />
    default:
      return <Home />
  }
}

export default function App() {
  return (
    <AuthProvider>
      <UnistoreProvider value={store}>
        <div id="app">
          <Router />
        </div>
      </UnistoreProvider>
    </AuthProvider>
  )
}
