import createStore from 'unistore'
import persistStore from 'unissist'
import indexedDBAdapter from 'unissist/integrations/indexdbAdapter'

const store = createStore({
  games: [],
  cachedUserData: {},
})

persistStore(store, indexedDBAdapter())

export default store
