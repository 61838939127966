/* global localStorage */
import { useState, useEffect, useContext, createContext } from 'react'
import { auth } from '../firebase'
import {
  onAuthStateChanged,
  signInAnonymously,
  signOut,
  updateProfile,
} from 'firebase/auth'

const authContext = createContext()

export function AuthProvider({ children }) {
  const authValue = useAuthProvider()
  return (
    <authContext.Provider value={authValue}>{children}</authContext.Provider>
  )
}

export default function useAuth() {
  return useContext(authContext)
}

function getStoredUser() {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch (_) {}
  return null
}

function useAuthProvider() {
  const [user, setUser] = useState(getStoredUser())

  // If already signed in, returns signed in user
  const createAnonymousUser = () => signInAnonymously(auth).then((r) => r.user)

  const _signOut = () => signOut(auth)

  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        console.log('Your user id:', user?.uid)

        if (user) {
          setUser(user)
          localStorage.setItem(
            'user',
            JSON.stringify({ uid: user.uid, displayName: user.displayName })
          )
        } else {
          setUser(false)
          localStorage.removeItem('user')
        }
      }),
    []
  )

  const updateDisplayName = (displayName) =>
    updateProfile(auth.currentUser, { displayName })

  return {
    user,
    createAnonymousUser,
    signOut: _signOut,
    updateDisplayName,
  }
}
