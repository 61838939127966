// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore'
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions'

const LOCAL_FUNCTIONS = process.env.NODE_ENV === 'development'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB0yMqdcd4BAQgJG5zF6iA9Sl5pwGObmUg',
  authDomain: 'quizquizquizquiz.firebaseapp.com',
  projectId: 'quizquizquizquiz',
  storageBucket: 'quizquizquizquiz.appspot.com',
  messagingSenderId: '2737153240',
  appId: '1:2737153240:web:1211e8c0ea5813f7c4a3ad',
  measurementId: 'G-Y9261MGY90',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const analytics = getAnalytics(app)
export const firestore = getFirestore(app)

const firebaseFunctions = getFunctions(app)
export const functions = {
  createInviteCode: httpsCallable(firebaseFunctions, 'createInviteCode'),
  createPracticeGame: httpsCallable(firebaseFunctions, 'createPracticeGame'),
  getUser: httpsCallable(firebaseFunctions, 'getUser'),
  joinWithInviteCode: httpsCallable(firebaseFunctions, 'joinWithInviteCode'),
  selectCategory: httpsCallable(firebaseFunctions, 'selectCategory'),
}
if (LOCAL_FUNCTIONS) {
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
}

enableIndexedDbPersistence(firestore).catch((err) =>
  console.log('Error with IndexedDB', err.code)
)
